import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { getAllPages, getPageByUrl, TABLO_LIST } from "./_index";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { accountAtom } from "../atoms/accountAtom";
import { endSubscription, subscribeForMessage } from "../utils/subscriber";
import { statsAtom } from "../atoms/statsAtom";
import { getPersianDay, timeAgo, toShamsi } from "../utils/date-utils";
import dateFormat from "dateformat";
import { Paper } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import logo from "../assets/logo192.png"
import { goldPriceAtom } from "../atoms/goldPriceAtom";
import { dollarPriceAtom } from "../atoms/dollarPriceAtom";

import goldImage from "../assets/gold_big.svg"
import dollarImage from "../assets/dollar.png"

import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';


function Home() {
  const [url, setUrl] = useAtom(urlAtom);

  const [account, setAccount] = useAtom(accountAtom);

  const [now, setNow] = useState(new Date());

  const [stats, setStats] = useAtom(statsAtom);

  const [goldPrice, setGoldPrice] = useAtom(goldPriceAtom)

  const [dollarPrice, setDollarPrice] = useAtom(dollarPriceAtom)

  const theme = useTheme();

  useEffect(() => {
    if (account) {
      const subStats = subscribeForMessage(
        "updateStats",
        updateStatsRecieved,
        "Home"
      );

      const subGoldPrice = subscribeForMessage(
        "updateGoldPrice",
        updateGoldPriceRecieved,
        "Home"
      );

      const subDollarPrice = subscribeForMessage(
        "updateDollarPrice",
        updateDollarPriceRecieved,
        "Home"
      );

      const interval = setInterval(() => {
        setNow(() => new Date());
      }, 1000);

      return () => {
        endSubscription(subStats);
        endSubscription(subGoldPrice)
        endSubscription(subDollarPrice)

        clearInterval(interval);
      };
    }
  }, [account]);

  const updateGoldPriceRecieved = (msg) => {

    setGoldPrice((prev) => {
      return {
        price: msg.goldPrice,
        priceOld: msg.goldPriceOld,
      };
    });
  };

  const updateDollarPriceRecieved = (msg) => {

    setDollarPrice((prev) => {
      return {
        price: msg.dollarPrice,
        priceOld: msg.dollarPriceOld,
      };
    });
  };

  const updateStatsRecieved = (msg) => {
    if (msg?.stats) {
      setStats((prev) => {
        return { stats: msg.stats, statsOld: msg.statsOld };
      });
    }
  };

  const renderTodayString = (date) => {
    return (
      <>
        <span className="text-gray-300 w-[100px] text-center">{"امروز"}</span>

        <span className="w-[70px]">{getPersianDay(date)}</span>

        <span className="tracking-wider text-gray-300">{toShamsi(date)}</span>
      </>
    );
  };

  const renderTomorrowString = (date) => {
    return (
      <>
        <span className="text-gray-300 w-[100px] text-center">
          {"تسویه فردایی"}
        </span>

        <span className="w-[70px]">{getPersianDay(date)}</span>

        <span className="tracking-wider text-gray-300">{toShamsi(date)}</span>
      </>
    );
  };


  const [priceChangeAmount, setPriceChangeAmount] = useState([])
  useEffect( () => {

    if (stats && stats.stats && stats.stats.tabloPrice && stats.statsOld && stats.statsOld.tabloPrice)
    {
      const temp = []
      for (let i=0; i < stats.stats.tabloPrice.length; i++)
      {
        temp.push({tablo: stats.stats.tabloPrice[i].tablo, priceChange: stats.stats.tabloPrice[i].price - stats.statsOld.tabloPrice[i].price})
      }

      setPriceChangeAmount(prev => temp)

      setTimeout(() => {
        setPriceChangeAmount(prev => [])
      }, 2000);

    }

  }, [stats])


  const [changePriceClass, setChangePriceClass] = useState("text-white") 

  const [changePriceClassDollar, setChangePriceClassDollar] = useState("text-white") 


  useEffect( () => {
    if (goldPrice)
    {
      const {price, priceOld} = goldPrice
      if (price?.length > 0 && priceOld?.length > 0)
      {
        const up = parseFloat(price.replace(",", "")) > parseFloat(priceOld.replace(",", ""))
        const down = parseFloat(price.replace(",", "")) < parseFloat(priceOld.replace(",", ""))
        if (up)
        {
          setChangePriceClass(prev => "text-[#26c988] ")
          setTimeout(() => {
            setChangePriceClass(prev => "text-gray-200 ")
          }, 1000);

        }else if (down)
        {
          setChangePriceClass(prev => "text-[#f52a2a]")
          setTimeout(() => {
            setChangePriceClass(prev => "text-gray-200  ")
          }, 1000);

        }else{
          setChangePriceClass(prev => "text-gray-200  ")
        }
      }
    }

  }, [goldPrice])


  useEffect( () => {
    if (dollarPrice)
    {
      const {price, priceOld} = dollarPrice
      if (price?.length > 0 && priceOld?.length > 0)
      {
        const up = parseFloat(price.replace(",", "")) > parseFloat(priceOld.replace(",", ""))
        const down = parseFloat(price.replace(",", "")) < parseFloat(priceOld.replace(",", ""))
        if (up)
        {
          setChangePriceClassDollar(prev => "text-[#26c988] ")
          setTimeout(() => {
            setChangePriceClassDollar(prev => "text-gray-200 ")
          }, 1000);

        }else if (down)
        {
          setChangePriceClassDollar(prev => "text-[#f52a2a]")
          setTimeout(() => {
            setChangePriceClassDollar(prev => "text-gray-200  ")
          }, 1000);

        }else{
          setChangePriceClassDollar(prev => "text-gray-200  ")
        }
      }
    }

  }, [dollarPrice])

  const renderGoldPrice = (price , oldprice) => {

    if (!price)
    {
      return <span> {""} </span>
    }

    let sameIndex = 0
    for (let i = 0 ; i < price.length; i++)
    {
      if (price[i] !== oldprice[i])
      {
        sameIndex = i
        break
      }
    }

    return (
      <span className="flex flex-row items-baseline justify-center fade-in text-gray-200" style={{ direction: "ltr" }}>
        <span className="text-xl tracking-wider font-semibold"> {price.substring(0, sameIndex)} </span>
        <span className={`${changePriceClass} transition-all duration-300 ease-in-out text-xl tracking-wider font-semibold`}>
          {price.substring(sameIndex, price.length)}
        </span>
        <span className="text-gray-400 text-sm pl-2">{"USD"}</span>
      </span>
    );
  }

  const renderDollarPrice = (price , oldprice) => {

    if (!price)
    {
      return <span> {""} </span>
    }

    let sameIndex = 0
    for (let i = 0 ; i < price.length; i++)
    {
      if (price[i] !== oldprice[i])
      {
        sameIndex = i
        break
      }
    }

    return (
      <span className="flex flex-row items-baseline justify-center fade-in text-gray-200" style={{ direction: "ltr" }}>
        <span className="text-xl tracking-wider font-semibold"> {price.substring(0, sameIndex)} </span>
        <span className={`${changePriceClassDollar} transition-all duration-300 ease-in-out text-xl tracking-wider font-semibold`}>
          {price.substring(sameIndex, price.length)}
        </span>
        <span className="text-gray-400 text-sm pl-2">{"TMN"}</span>
      </span>
    );
  }



  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center  gap-4 py-[20px] px-[20px] bg-opacity-50`}
      style={{ background: theme.palette.background.default }}
    >
      {/* Calendar  *************************/}
      <div
        className="flex flex-col gap-0 w-full p-2 rounded-xl fade-in pb-5 "
        style={{ background: theme.palette.background.paperGrad }}
      >
        <p
          style={{ color: theme.palette.secondary.main }}
          className="flex flex-row justify-between items-center pb-2 "
        >
          <CalendarMonthOutlinedIcon />

          <span
            className="tracking-wider text-sm p-1 rounded-lg w-[120px] h-full text-center "
            style={{
              direction: "ltr",
              background: theme.palette.background.surface,
            }}
          >{`${dateFormat(now, "H:MM:ss TT")}`}</span>

          <span className="w-[25px]"></span>
        </p>

        <p className="text-white flex flex-row gap-2 items-center w-full justify-center py-[10px]">
          {renderTodayString(stats?.stats?.now ?? now)}
        </p>

        <p className="text-white flex flex-row gap-2 items-center w-full justify-center">
          {renderTomorrowString(
            stats?.stats?.tomorrow ??
              new Date(new Date().setDate(now.getDate() + 1))
          )}
        </p>
      </div>

      {/* end of Calendar  *************************/}

      {/* Gold Price */}
      <div
        className="flex flex-col gap-0 w-full p-2 rounded-xl fade-in py-2 pr-8 "
        style={{ background: theme.palette.background.paper }}
      >
        <p className="flex flex-row items-center justify-between">
          <p
            className="flex flex-row gap-2 items-center"
            style={{ color: theme.palette.primary.main }}
          >
            <span>
              <img src={goldImage} className="rounded-full" width={"30px"} />
            </span>
            <span>{" اونس طلا"}</span>
          </p>
          <span className="text-white w-[200px]">
            {renderGoldPrice(goldPrice?.price, goldPrice?.priceOld)}
          </span>
        </p>
      </div>

      {/* Dollar Price */}
      <div
        className="flex flex-col gap-0 w-full rounded-xl fade-in pr-5 "
        style={{ background: theme.palette.background.paper }}
      >
        <p className="flex flex-row items-center justify-between">
          <p
            className="flex flex-row justify-start items-center w-full"
            style={{ color: theme.palette.primary.main }}
          >
            <span className="overflow-y-hidden h-[70px]">
              <img src={dollarImage} width={"150px"} />
            </span>
          </p>
          <span className="text-white w-[200px] pl-[35px]">
            {renderDollarPrice(dollarPrice?.price, dollarPrice?.priceOld)}
          </span>
        </p>
      </div>

      {/* Mazane Estimate */}
      {(account?.username === "admin" || account?.username === "admintest" || account?.username === "9124219921") && (
        <div
          className="flex flex-col gap-0 w-full rounded-xl fade-in pr-5 "
          style={{ background: theme.palette.background.paper }}
        >
          <p className="flex flex-row items-center justify-between">
            <p
              className="flex flex-row justify-start items-center w-full h-full gap-2"
              style={{ color: "#00edd5" }}
            >
              <SmartToyOutlinedIcon />
              <span className="">تخمین مظنه</span>
            </p>
            {dollarPrice && goldPrice && dollarPrice.price && goldPrice.price && (
              <span className="text-[#00edd5] w-[200px] pl-[35px] text-xl font-bold tracking-wider text-center">
                { Math.round(parseFloat(dollarPrice.price.replace(",", "")) * parseFloat(goldPrice.price.replace(",", "")) * 1.068 / 10000).toLocaleString("en")}
              </span>
            )}
          </p>
        </div>
      )}

      {/* Tablo Prices  *************************/}
      <div
        className={`flex flex-col justify-between w-full p-2 rounded-xl fade-in pb-5 items-center overflow-hidden h-full "}`}
        style={{ background: theme.palette.background.paperGrad }}
      >
        <p
          style={{ color: theme.palette.secondary.main }}
          className="flex flex-row gap-2 items-center justify-center"
        >
          <TimelineOutlinedIcon />

          <span className="tracking-wider p-1 rounded-lg text-center ">{`آخرین قیمت های معامله شده`}</span>
        </p>

        {stats?.stats?.tabloPrice?.map((item) => (
          <p className="w-full flex flex-row justify-between items-center text-white h-[70px] fade-in">
            <span
              className="flex items-center justify-center gap-1 text-sm w-[110px]"
              style={{ color: theme.palette.secondary.main }}
            >
              <span className="text-center">
                {TABLO_LIST.find((r) => r.tablo === item.tablo)?.title || ""}
              </span>
              <span>
                {TABLO_LIST.find((r) => r.tablo === item.tablo)?.icon || ""}
              </span>
            </span>

            <p className="flex flex-row items-center justify-center">
              <span className="w-[50px]">
                {priceChangeAmount?.find(
                  (r) => r.tablo === item.tablo && r.priceChange !== 0
                ) && (
                  <span className="fade-in flex flex-col justify-center items-center">
                    <span
                      className="text-[11px] text-center w-full"
                      style={
                        priceChangeAmount.find((r) => r.tablo === item.tablo)
                          .priceChange > 0
                          ? { color: "#33ff36", direction: "ltr" }
                          : { color: "#ff4a4a", direction: "ltr" }
                      }
                    >
                      {` ${
                        priceChangeAmount.find((r) => r.tablo === item.tablo)
                          .priceChange > 0
                          ? "+"
                          : ""
                      }
                         ${priceChangeAmount
                           .find((r) => r.tablo === item.tablo)
                           .priceChange.toLocaleString(
                             "en"
                           )}                       
                      `}
                    </span>

                    {priceChangeAmount.find((r) => r.tablo === item.tablo)
                      .priceChange > 0 ? (
                      <ArrowUpwardOutlinedIcon
                        sx={{ width: "18px", color: "#33ff36" }}
                      />
                    ) : (
                      <ArrowDownwardOutlinedIcon
                        sx={{ width: "18px", color: "#ff4a4a" }}
                      />
                    )}
                  </span>
                )}
              </span>

              <span className="w-[100px] text-right text-sm text-yellow-300 tracking-wide font-bold ">
                {item.price > 0 ? `${item.price.toLocaleString("en")}` : "-"}
              </span>
            </p>

            <span className="text-xs text-gray-200 w-[70px] text-center">
              {item.price > 0
                ? `${timeAgo(new Date(item.timeStamp), now)}`
                : "-"}
            </span>
          </p>
        ))}
      </div>

      {/* end of Tablo Prices  *************************/}

      {/* Total Trades  *************************/}

      {/* <div
        className="flex flex-col gap-4 w-full p-2 rounded-xl fade-in pb-5"
        style={{ background: theme.palette.background.paperGrad }}
      >
        <p
          style={{ color: theme.palette.secondary.main }}
          className="flex flex-row gap-2 items-center"
        >
          <BarChartOutlinedIcon />

          <span className="tracking-wider p-1 rounded-lg text-center ">{`آمار معاملات`}</span>
        </p>

        <p
          className="flex flex-row items-center justify-around"
          style={{ color: theme.palette.secondary.main }}
        >
          <p
            className="flex flex-col gap-2 items-center justify-center px-5 py-2 rounded-xl text-sm"
            style={{ background: theme.palette.background.surface }}
          >
            <span>{"معاملات امروز"}</span>
            <span className="flex flex-row items-baseline gap-2">
              <span>{`${stats?.stats?.sumDealToday ?? 0}`}</span>
              <span className="opacity-70 text-xs">{"کیلو"}</span>
            </span>
          </p>

          <p
            className="flex flex-col gap-2 items-center justify-center px-5 py-2 rounded-xl text-sm "
            style={{ background: theme.palette.background.surface }}
          >
            <span>{"معاملات دیروز"}</span>
            <span className="flex flex-row items-baseline gap-2">
              <span>{`${stats?.stats?.sumDealYesterday ?? 0}`}</span>
              <span className="opacity-70 text-xs">{"کیلو"}</span>
            </span>
          </p>
        </p>
      </div>
      end of Total Trades  ************************ */}

      {/* <div className="w-full h-full overflow-hidden flex justify-start items-center rounded-3xl fade-in "
        style={{background: "linear-gradient(-90deg, rgba(0,0,0,1) 20%, rgba(45,45,45,1) 100%)"}}
      >

        <img src={logo} width={"90px"} />

        <p className="text-gray-200 text-center p-2 leading-8 w-full " >
                {"بالاترین سرعت را تجربه کنید!"}
        </p>
        
      </div> */}
    </div>
  );
}

export default Home;
